const cardName = Object.freeze({
  Visa: 'visa',
  MasterCard: 'mastercard',
  AmericanExpress: 'amex',
  UnionPay: 'unionpay',
  DinersClub: 'diners',
  Discover: 'discover',
  Generic: 'cb',
});

export default cardName;
