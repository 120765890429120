<template>
  <section id="cards">
    <div class="page">
      <app-tabs :tabs="[
        { label: 'Plans', route: { name: 'plans' } },
        { label: 'Invoices', route: { name: 'invoices' } },
        { label: 'Cards', route: { name: 'cards' } }]"
      />

      <div class="loading" v-if="isLoading">
        <app-spinner color="primary" />
      </div>

      <div v-else-if="paymentMethods && paymentMethods.data.length" class="card-list">
        <div v-for="paymentMethod in paymentMethods.data" :key="paymentMethod.id" class="card grid-x">
          <div class="cell shrink card-image">
            <component :is="cardNames[paymentMethod.card.brand.replace(/\s/g,'')] || cardNames.Generic" />
          </div>

          <div class="cell auto">
            <p class="card-numbers">{{ paymentMethod.card.brand }} **** **** **** {{ paymentMethod.card.last4 }}</p>
            <p class="expiration-date">Expires {{ `0${paymentMethod.card.exp_month}`.slice(-2) }}/{{ paymentMethod.card.exp_year }}</p>
            <p class="invalid-warning" v-if="paymentMethod.id.startsWith('card')">not configured</p>
            <p v-if="defaultPaymentMethodId !== paymentMethod.id" class="delete" @click="isModalDeleteCardVisible = true, cardToDelete = paymentMethod.id">Delete</p>
          </div>

          <div class="cell shrink selection-card-container">
            <div v-if="cardIdToSetDefault === paymentMethod.id">
              <app-spinner class="loader" color="primary" small />
            </div>

            <div v-else-if="defaultPaymentMethodId">
              <app-radio :input="defaultPaymentMethodId" :value="paymentMethod.id" @input="setDefaultCard" :disabled="paymentMethod.id.startsWith('card')"/>
            </div>
          </div>
        </div>

        <div class="grid-x add-card-button" v-if="paymentMethods && paymentMethods.data.length">
          <div class="cell auto"/>
          <div class="cell shrink">
            <app-button @click="isModalAddCardVisible = true">+ Add a new payment method</app-button>
          </div>
        </div>
      </div>

      <div class="grid-x no-cards" v-else>
        <div class="cell auto">
          <no-card />
          <p>
            Don’t worry the basic plan is still free and we won’t charge you anything without your consent. <br>
            We have to do so to limit the abusive accounts creation.
          </p>
          <app-button @click="isModalAddCardVisible = true">+ Add a new payment method</app-button>
        </div>
      </div>

      <app-modal :show.sync="isModalAddCardVisible" class="add-card-modal">
        <cancel @click="isModalAddCardVisible = false" />
        <add-card @add-card="addCard" :setAsDefault="paymentMethods && !paymentMethods.data.length"/>
      </app-modal>

      <app-modal :show.sync="isModalDeleteCardVisible" title="Delete card">
        <p>Are you sure you want to delete this credit card ? This action cannot be undone.</p>
        <template v-slot:footer>
          <app-button look="ghost" @click="isModalDeleteCardVisible = false">Cancel</app-button>
          <app-button @click="deleteCard(cardToDelete)" look="warning">Delete card</app-button>
        </template>
      </app-modal>
    </div>
  </section>
</template>

<script>
import stripeApi from '@/services/api/stripe';
import noCard from '@/assets/img/no-card.svg?inline';
import cancel from '@/assets/img/cancel.svg?inline';
import visa from '@/assets/img/card/visa.svg?inline';
import mastercard from '@/assets/img/card/mastercard.svg?inline';
import amex from '@/assets/img/card/amex.svg?inline';
import unionpay from '@/assets/img/card/unionpay.svg?inline';
import discover from '@/assets/img/card/discover.svg?inline';
import diners from '@/assets/img/card/diners.svg?inline';
import cb from '@/assets/img/card/cb.svg?inline';

import AddCard from '@/views/cards/AddCard.vue';

import cardName from '@/enums/cardName';

export default {
  name: 'cards',

  components: {
    'no-card': noCard,
    cancel,
    AddCard,
    visa,
    mastercard,
    amex,
    unionpay,
    discover,
    diners,
    cb,
  },

  data() {
    return {
      paymentMethods: null,
      defaultPaymentMethodId: null,
      isModalAddCardVisible: null,
      isModalDeleteCardVisible: null,
      cardToDelete: null,
      cardNames: cardName,
      cardIdToSetDefault: null,
      isLoading: null,
    };
  },

  mounted() {
    this.getCards();
  },

  methods: {
    async getCards() {
      this.isLoading = true;

      try {
        this.paymentMethods = await stripeApi.getCards();

        if (this.paymentMethods && this.paymentMethods.data.length) {
          this.getDefaultCard();
        }

        // On envoie les informations de la carte à l'app
        this.$emit('updateCardInformation', { cards: this.paymentMethods });
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Impossible to find your credit cards',
          confirmText: 'Ok',
          hasCancel: false,
        });

        // On envoie les informations de la carte à l'app
        this.$emit('updateCardInformation', { error: true });
      } finally {
        this.isLoading = false;
      }
    },

    // Récupère la carte de crédit par défaut de l'utilisateur
    async getDefaultCard() {
      try {
        const defaultCard = await stripeApi.getDefaultCard();
        this.defaultPaymentMethodId = defaultCard.id;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Impossible to find your default credit card',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },

    // Défini la carte par défaut de l'utilisateur
    async setDefaultCard(evt) {
      this.cardIdToSetDefault = evt;

      try {
        const defaultCard = await stripeApi.setDefaultCard(this.cardIdToSetDefault);
        this.defaultPaymentMethodId = defaultCard.id;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Impossible to set your default credit card',
          confirmText: 'Ok',
          hasCancel: false,
        });
      } finally {
        this.cardIdToSetDefault = null;
      }
    },

    addCard() {
      this.isModalAddCardVisible = false;
      this.getCards();
    },

    async deleteCard(cardId) {
      try {
        if (this.paymentMethods.data.length > 1) {
          await stripeApi.deleteCard(cardId);
          this.$notification.show({
            text: 'The card has been deleted successfully !',
          });

          this.getCards();
          this.isModalDeleteCardVisible = false;
        } else {
          this.$message.show({
            title: 'Erreur',
            text: 'You must have at least one card',
            confirmText: 'Ok',
            hasCancel: false,
          });
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Impossible to delete your card',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },
  },
};
</script>

<style lang="sass">
#cards
  .page
    @include page

    h1
      color: $white
      margin-bottom: 30px

      svg
        margin-right: 10px

    .card-list
      margin-top: 30px

    .menu-card
      background-color: $primary80
      padding: 8px 16px
      border-radius: 8px
      margin-right: 8px

      &.router-link-exact-active
        background-color: $primary

      h4
        color: white

    .card-image

      svg
        height: 30px

    .loading
      width: 100px
      margin: 50px auto

    .no-cards
      max-width: 400px
      margin: 2rem auto 0 auto

      .cell
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center

      p
        margin: 2.5rem 0 4rem 0
        text-align: center
        line-height: 16px

      svg
        width: 200px

  .card
    background-color: $primary80
    padding: 24px
    border-radius: 8px
    margin-top: 20px

    .card-numbers
      color: white
      @include label-bold
      margin-left: 24px
      margin-bottom: 5px
      text-transform: capitalize

    .expiration-date
      @include label
      color: $primary20
      margin-left: 24px
      margin-bottom: 5px

    .invalid-warning
      @include title-xsmall
      color: $warning
      margin-left: 24px
      margin-bottom: 5px

    .delete
      margin-left: 24px
      color: $accent100
      text-decoration: underline
      cursor: pointer
      display: inline-block

    .loader
      margin-top: 9px
      margin-left: 5px
      transform: scale(1.7)

    .selection-card-container
      width: 26px

      label
        margin: 0

  .add-card-button
    margin-top: 30px

  .add-card-modal

    .modal-wrapper
      background-color: $primary90
      padding-top: 20px

      svg
        position: absolute
        top: 20px
        right: 20px
        width: 15px
        cursor: pointer

</style>
